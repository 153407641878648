import React, { ReactElement } from "react";
import MainImg from "./mainImg";

export default function GetStartedToday(): ReactElement {
    return (
        <section className="mt-20 lg:mt-40 bg-white">
            <div className="relative w-[321px] h-[194px] lg:w-[817px] lg:h-[496px] mx-auto">
                <MainImg />
            </div>
            <div className="mt-8 lg:mt-16 max-w-[817px] mx-auto">
                <h2 className="font-extrabold text-[25px] lg:text-[50px]">
                    Get Started Today
                </h2>
                <p
                    style={{ color: "#4C4C4C" }}
                    className="font-light text-sm lg:text-xl mt-3"
                >
                    Don't miss out on the opportunity to reach a wider audience
                    and grow your business through our marketplace. Whether
                    you're an established home warranty provider or an emerging
                    company, PremiumCoverage.com is your gateway to expanding
                    your market presence and driving more sales.
                </p>
            </div>
        </section>
    );
}
