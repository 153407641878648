import React from "react";
import styles from "./style.module.scss";
function PrivacyPolicy({ modalStyle }: { modalStyle?: boolean }) {
    return (
        <section
            className={`${styles["mainContainer"]} ${
                modalStyle ? styles["modalStyle"] : ""
            }`}
        >
            <h1>
                PremiumCoverage' Privacy and Data Protection Policy and Notice
            </h1>
            <div className={styles["timeStyle"]}>
                Last Updated: September 2023
            </div>
            <p>
                PremiumCoverage,{" "}
                <a href="https://premiumcoverage.com/">premiumcoverage.com</a>,
                ("Company," "we," or “our”), is dedicated to safeguarding your
                privacy and providing a secure user experience. This Privacy
                Policy outlines how we gather, store, share, and utilize
                personal and non-personal information collected on our website.
                By accessing and using our website, you explicitly agree to the
                collection, use, and transfer of personal and non-personal
                information as described in this Privacy Policy. Please read
                this Privacy Policy carefully to understand our practices
                regarding your personal information. If you disagree with our
                data collection and processing practices, please refrain from
                using our website.
            </p>
            <ol className={styles["decimalStyle"]}>
                <li>
                    <h2>Information We Collect</h2>
                    <p>
                        We collect various types of information about you when
                        you visit and use our website. This data falls into two
                        main categories: non-personal information and personal
                        information. Personal Information: This includes
                        personally identifiable details voluntarily provided by
                        you, your organization, or associated parties while
                        using our website. It may encompass your full name,
                        address, phone number, email address, mobile number,
                        payment source details, or any other personal
                        information you provide when communicating with us
                        through our website. We may collect similar information
                        for all individuals you represent. Providing this
                        information is optional, but please note that declining
                        to do so may affect your eligibility for certain
                        products, services, sweepstakes, contests, or
                        promotions.
                    </p>
                    <p>
                        We may also collect information about your website
                        usage, the specific sections you visit, the services you
                        access, and details about your computer hardware and
                        software, including domain names, internet access times,
                        visited websites, and location data related to your
                        device's geographic location.
                    </p>
                </li>
                <li>
                    <h2>How We Collect Your Personal Information</h2>
                    <p>
                        We collect Personal Information through your use of our
                        website, when you engage with our services, and during
                        interactions with us. We employ technologies like
                        cookies, web beacons, pixel tags, log files, and local
                        storage to automatically gather information when you
                        visit and use our website. These technologies collect
                        data such as IP addresses, browser types, ISPs,
                        referring/exit pages, operating systems, timestamps, and
                        clickstream data. This information helps us analyze
                        trends, manage our website, track user activities, and
                        obtain demographic insights. We may also use cookies on
                        your devices (refer to the 'Cookies' section below).
                        Additionally, we collect Personal Information when you
                        express interest or submit requests. Information may be
                        provided manually or automatically as you use our
                        website and services. We may also collect Personal
                        Information when you contact us for support.
                    </p>
                </li>
                <li>
                    <h2>How We Use Information Collected</h2>
                    <ol className={styles["alphaStyle"]}>
                        <li>
                            <h3>Providing Services and Products:</h3>
                            <p>
                                We use the information gathered on our website
                                to fulfill your requests for products and
                                services. This may involve sharing your
                                information with third parties to provide these
                                services or products. These third parties are
                                required to comply with applicable laws, but we
                                cannot guarantee their privacy practices. If you
                                have questions or wish to remove your
                                information from third-party databases, please
                                contact the third party directly.
                            </p>
                        </li>
                        <li>
                            <h3>Improving Our Website:</h3>
                            <p>
                                We use the information to respond to your
                                inquiries, enhance website functionality,
                                deliver advertised products and services, and
                                personalize your experience. We also use this
                                data to fulfill legal obligations and address
                                disputes or concerns.
                            </p>
                        </li>
                        <li>
                            <h3>Marketing:</h3>
                            <p>
                                We use the information you provide to send you
                                advertisements, promotions, and content from
                                third-party parties that may interest you. We
                                may anonymize and aggregate information for
                                various purposes, including traffic analysis,
                                usage analysis, product development, market
                                research, and reporting. We may share this
                                information, along with the Personal Information
                                and non-personal information you provide, with
                                our affiliates and parent companies to offer
                                products and services of potential interest.
                            </p>
                        </li>
                    </ol>
                    <p>
                        If you wish to opt out of receiving promotional emails
                        or other communications from PremiumCoverage or third
                        parties, you can do so by (1) emailing your request to{" "}
                        <a href="mailto:info@premiumcoverage.com">
                            info@premiumcoverage.com
                        </a>
                        , or (2) using the "unsubscribe" link in emails from
                        Company. Please note that this opt-out does not apply to
                        information obtained through product purchases, warranty
                        registrations, product service experiences, or other
                        transactions.
                    </p>
                </li>
                <li>
                    <h2>Disclosure of Information to Others</h2>
                    <p>
                        We share your Personal Information with third parties as
                        described in this Privacy Policy.
                    </p>
                    <ol className={styles["alphaStyle"]}>
                        <li>
                            <h3>Product and Service Requests:</h3>
                            <p>
                                If you request information about products or
                                services from third parties on our website, we
                                share the information you provide with those
                                third parties to provide you with additional
                                details about the requested products and
                                services. If you provide your phone number or
                                email address, you consent to these third
                                parties contacting you via telephone, email, or
                                text for purposes such as marketing and
                                telemarketing about insurance products and
                                services. You also authorize these third parties
                                to contact you even if your phone number is on
                                Do Not Call lists or registries. These third
                                parties may verify the information you provided
                                through other sources, including credit scores
                                and driving records. You are not obligated to
                                purchase products or services from these third
                                parties, and you can revoke your consent at any
                                time by contacting the third party that
                                initially contacted you.
                            </p>
                        </li>
                        <li>
                            <h3>Website Functionality:</h3>
                            <p>
                                We share your information with companies and
                                individuals who perform technical functions on
                                our behalf. This includes third-party service
                                providers responsible for website hosting, data
                                analysis, marketing support, credit card
                                processing, and customer service. These service
                                providers are required to use your information
                                solely for performing technical functions on our
                                behalf and are not authorized to share it with
                                third parties or use it for other purposes.
                            </p>
                        </li>
                        <li>
                            <h3>Anonymous Information:</h3>
                            <p>
                                We aggregate and share anonymous information
                                about website visitors with third parties to
                                help them understand our website's user
                                demographics and behavior.
                            </p>
                        </li>
                        <li>
                            <h3>Legal Process:</h3>
                            <p>
                                We may disclose your information when legally
                                required, at our discretion, in response to
                                government requests, or when we believe in good
                                faith that it is necessary to:
                            </p>
                            <ul>
                                <li>
                                    Comply with legal requirements or legal
                                    processes.
                                </li>
                                <li>
                                    Protect our rights and property and those of
                                    our affiliated companies.
                                </li>
                                <li>
                                    Prevent a crime or safeguard national
                                    security.
                                </li>
                                <li>
                                    Ensure the personal safety of users or the
                                    public.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Acquisition or Merger:</h3>
                            <p>
                                If our business is acquired, whether through
                                merger, consolidation, or asset purchase, we may
                                disclose and transfer your information to the
                                acquiring third party. In the event of
                                insolvency proceedings, we or our liquidator,
                                administrator, receiver, or administrative
                                receiver may sell, license, or otherwise dispose
                                of your information as approved by the court.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Third-Party Collection and Use of Information</h2>
                    <p>
                        Third parties collect and use information about you
                        through our website in the following ways:
                    </p>
                    <ol className={styles["alphaStyle"]}>
                        <li>
                            <h3>Advertisers:</h3>
                            <p>
                                Advertising agencies, advertising networks, and
                                other companies that place ads on our website
                                may employ their own cookies, web beacons, and
                                technology to collect information about you.
                                While we require advertisers to comply with the
                                law, we cannot guarantee their technology's
                                compliance.
                            </p>
                        </li>
                        <li>
                            <h3>Hyperlinks:</h3>
                            <p>
                                Our website and email messages may contain links
                                to third-party websites. We are not responsible
                                for these external websites' privacy practices
                                or content. These links are provided for
                                convenience, and we do not control the
                                information, software, products, or services
                                available on these third-party websites. Their
                                inclusion on our website does not imply
                                endorsement. Review their privacy policies to
                                learn about the data practices of these
                                websites.
                            </p>
                        </li>
                        <li>
                            <h3>Analytics:</h3>
                            <p>
                                Third parties are used to monitor, analyze, and
                                report on website and email traffic to, from,
                                and within our platform.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Where We Store Your Data</h2>
                    <p>
                        We store your Personal Information on servers owned or
                        controlled by us or processed by reputable cloud service
                        providers in the United States (refer to the section on
                        international transfers).
                    </p>
                </li>
                <li>
                    <h2>International Data Transfers (EU Residents)</h2>
                    <p>
                        Personal Information may be transferred to, stored, and
                        processed in destinations outside the European Union,
                        which may not have equivalent data protection laws. When
                        your Personal Information is transferred outside the
                        EEA, we take reasonable steps to ensure it receives
                        appropriate safeguards and is treated securely in
                        accordance with this Privacy Policy. Data is transferred
                        to the United States of America, subject to the service
                        provider's commitment to Standard Contractual Clauses
                        (SCCs).
                    </p>
                    <p>
                        We may transfer your Personal Information outside the EU
                        for purposes such as data storage, backups, contractual
                        obligations, legal, ethical, or compliance requirements,
                        facilitating our group businesses, and serving customers
                        across multiple jurisdictions.
                    </p>
                </li>
                <li>
                    <h2>Services and Website Data Collection and Cookies</h2>
                    <p>
                        When you use our services or website, we may employ
                        industry-standard technologies like cookies, pixels, and
                        similar mechanisms to store certain information on your
                        computer or browsing device. These technologies help
                        identify your device and, in some cases, associate it
                        with a user, enabling automatic activation of features
                        and enhancing your user experience. We use both session
                        cookies and persistent cookies, with persistent cookies
                        remaining after you close your browser and being used on
                        subsequent website visits. You can manage your cookies
                        by adjusting your browser settings. For more information
                        on cookies, visit http://cookiecentral.com. Accepting
                        cookies may be necessary to access all features and
                        functionality on our website.
                    </p>
                    <p>
                        We collaborate with third-party marketing companies to
                        serve advertisements using cookies. You can configure
                        your browser to block some or all cookies or receive
                        notifications when cookies are sent. For Flash cookie
                        settings, consult Adobe's website. Disabling or
                        rejecting cookies may make certain website areas
                        inaccessible or function improperly.
                    </p>
                </li>
                <li>
                    <h2>Security</h2>
                    <p>
                        We employ measures to protect your information, both
                        online and offline. Information submitted on our website
                        is secured both online and offline. Our servers are
                        located in secure physical environments with
                        industry-standard firewalls, password protection, and
                        restricted employee access. We use Secure Socket Layer
                        (SSL) software to encrypt data transmitted over the
                        internet, including credit card numbers, names, and
                        addresses. While we take reasonable technological
                        precautions, no internet data transmission can be
                        guaranteed to be 100% secure. Consequently, we do not
                        warrant that your information will be completely secure.
                        Transmitting data through our website is at your own
                        risk.
                    </p>
                </li>
                <li>
                    <h2>EU Data Subject Rights</h2>
                    <p>
                        Data subjects to whom GDPR applies have rights under
                        GDPR and local laws. These rights may include data
                        portability, access to data, data rectification,
                        objections to processing, and data erasure. Please note
                        that data subject rights must be exercised in a manner
                        consistent with the rights of our employees and staff
                        and our proprietary and third-party rights. Certain
                        data, such as job references, reviews, proprietary
                        information, and intellectual property, cannot be
                        accessed, erased, or rectified by data subjects. Rights
                        may not apply to unstructured data like emails or where
                        other exemptions exist. If processing relies on consent,
                        data subjects generally have the right to withdraw
                        consent.
                    </p>
                    <p>
                        Data subjects wishing to modify, delete, or retrieve
                        their Personal Information can do so by contacting us at{" "}
                        <a href="mailto:info@premiumcoverage.com">
                            info@premiumcoverage.com
                        </a>
                        . We may need to verify your identity before processing
                        your request and may retain records of rights exercised
                        for compliance and audit purposes. Depending on
                        technical and commercial feasibility, Personal
                        Information may be deleted or aggregated without
                        identifiers.
                    </p>
                    <p>
                        EU data subjects can also lodge complaints with their
                        local data protection supervisory authority. If a
                        supervisory authority fails to address your complaint,
                        you may have the right to seek judicial remedies.
                    </p>
                </li>
                <li>
                    <h2>Minors</h2>
                    <p>
                        Our website is not intended for children under 16 years
                        old, and we do not knowingly collect Personal
                        Information from individuals under 16. If you are under
                        16, please do not use our website or provide any
                        information. If we discover that we have inadvertently
                        collected information from a child under 16 without
                        parental consent, we will promptly delete it. If you
                        believe we have collected information about a child
                        under 16, please contact us at{" "}
                        <a href="mailto:info@premiumcoverage.com">
                            info@premiumcoverage.com
                        </a>
                    </p>
                </li>
                <li>
                    <h2>Changes to Privacy Policy</h2>
                    <p>
                        We reserve the right to make material changes to this
                        Privacy Policy. We will notify you of such changes
                        through a prominent notice on our website to ensure you
                        stay informed about our data collection, usage, and
                        disclosure practices.
                    </p>
                </li>
                <li>
                    <h2>California Residents</h2>
                    <p>
                        If you are a California resident, California law may
                        grant you additional privacy rights regarding your
                        Personal Information. Please refer to our{" "}
                        <a href="https://premiumcoverage.com/ccpa-privacy-notice">
                            CCPA PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
                        </a>{" "}
                        for more information.
                    </p>
                </li>
                <li>
                    <h2>Nevada Residents</h2>
                    <p>
                        Nevada residents can exercise their sale opt-out rights
                        under Nevada Revised Statutes Chapter 603A by sending a
                        request to{" "}
                        <a href="mailto:info@premiumcoverage.com">
                            info@premiumcoverage.com
                        </a>
                    </p>
                </li>
                <li>
                    <h2>"Do Not Track" Signals</h2>
                    <p>
                        We do not currently support or respond to "Do Not Track"
                        signals set in web browsers.
                    </p>
                </li>
                <li>
                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy,
                        please reach out to us using the following contact
                        information:
                    </p>
                    <div>
                        <address>
                            PremiumCoverage
                            <br />
                            2810 N Church St #56440 <br />
                            Wilmington, DE 19802
                            <br />
                            Email:{" "}
                            <a href="mailto:info@premiumcoverage.com">
                                info@premiumcoverage.com
                            </a>{" "}
                            <br />
                            Phone Number:{" "}
                            <a href="tel:12817778404">+1 (281) 777-8404</a>
                        </address>
                    </div>
                </li>
            </ol>
        </section>
    );
}

export default PrivacyPolicy;
